<template>
    <h2> Office Staff </h2>
    <Carousel :value="staff" :numVisible="3" :numScroll="1" :autoplayInterval="5000">
        <!-- <template #header>
            <h2>Basic</h2>
        </template> -->
        <template #item="staffMember">
            <div class="car-item">
                <div class="car-content">
                    <div>
                        <img :src="require('@/assets/office-staff/images/' + staffMember.data.img_src)"
                            :alt="staffMember.data.name">
                            <div class="personName"><h2>{{staffMember.data.name}} <span class="title">{{staffMember.data.title}}</span></h2> </div>
                            {{staffMember.data.position}}
                    </div>
                </div>
            </div>
        </template>
    </Carousel>
</template>

<script>
import Carousel from 'primevue/carousel';
export default {
    name: 'OfficeStaff',
    components: {
        Carousel
    },
    data() {
        return {
            staff: [
                {
                    name: "Jenny L.",
                    position: "Insurance and Billing",
                    img_src: "photo-coming-soon.jpg"
                },
                {
                    name: "Tami D.",
                    title: "LPN",
                    position: "Nurse",
                    img_src: "Tami.jpg"
                },
                {
                    name: "Donita M.",
                    title: "LPN",
                    position: "Nurse",
                    img_src: "photo-coming-soon.jpg"
                },
                {
                    name: "Kim W.",
                    title:"RN",
                    position: "Receptionist",
                    img_src: "photo-coming-soon.jpg"
                },
                {
                    name: "Lisa L.",
                    position: "Patient Assistance Director",
                    img_src: "Lisa.jpg"
                },
                {
                    name: "Cathy M.",
                    position: "Receptionist",
                    img_src: "photo-coming-soon.jpg"
                },
                {
                    name: "Krystal H.",
                    position: "Receptionist",
                    img_src: "photo-coming-soon.jpg"
                }
            ],
            responsiveOptions: [
				{
					breakpoint: '1024px',
					numVisible: 3,
					numScroll: 3
				},
				{
					breakpoint: '600px',
					numVisible: 2,
					numScroll: 2
				},
				{
					breakpoint: '480px',
					numVisible: 1,
					numScroll: 1
				}
			]
        }
    }
}
</script>

<style scoped>
img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
}
.title {
    font-size: .65em;
}
</style>